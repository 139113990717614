import { render, staticRenderFns } from "./openApp.vue?vue&type=template&id=31f7c4c0&scoped=true"
import script from "./openApp.vue?vue&type=script&lang=js"
export * from "./openApp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f7c4c0",
  null
  
)

export default component.exports