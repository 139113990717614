<template>
  <div>
    <div class="wxopen" >
      <wx-open-launch-app id="openapp" @error="handleErrorFn" @launch="handleLaunchFn" appid="wxe06561735df56f0f"
                          extinfo="{dataId:'aaa'}">
        <script type="text/wxtag-template">
          <div class="" style="height: 20px;padding: 0 10px;color: #1890ff;">去APP查看</div>
        </script>
      </wx-open-launch-app>
      <span v-if="openLink" class="paddzy10 font14">
        <a :href="openUrl">去APP查看</a>
      </span>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins"
import axios from "axios";
import sha1 from "js-sha1";
import wx from "weixin-js-sdk";
import {Toast} from "vant";
import {getAccessToken} from "@/api/api";
export default {
  name: "openApp",
  mixins: [mixins],
  props: ['ids'],
  data(){
    return{
      ticket: "",
      openLink:false,
      openUrl:"",
      id: "wxe06561735df56f0f"//wxc86332f5a8e3eb56
    }
  },
  mounted() {
    if(this.isWeixin()) {
      this.gettic();
    }
    else{
      this.openLink=true;
      this.openUrl="sixu://sixu.work?dataId="+this.ids.dataId+"&appId="+this.ids.appId+"&companyId="+this.ids.companyId+"&FiledStatus="+this.ids.FiledStatus+"&upDataId="+this.ids.upDataId;
    }
    //console.log("ids",this.ids);
  },

  methods: {
    async gettic(){
      //获取access_token
      let accesstoken = await getAccessToken().then(res => {
        //console.log("accesstoken",res)
        if (res != null && res.data != null && res.data.content != null){
          if (!this.isEmptyData(res.data.content)){
            /*alert("ticket"+res.data.content)*/
            return res.data.content;
          }else {
            Toast.fail(res.data.errorMsg);
          }
        }
      })

      if (!this.isEmptyData(accesstoken)){
        //获取token
        let url = '/cgi-bin/ticket/getticket?access_token='+accesstoken+'&type=jsapi'
        this.ticket = await axios({
          method: 'get',
          url: '/tapi' + url,
        }).then(res=>{
          //console.log("res",res)
          //Toast("ticket"+res.data)
          if (res != null && res.data != null){
            if (!this.isEmptyData(res.data.ticket)){
              //Toast(res.data.ticket)
              return  res.data.ticket
            }else {
              //Toast.fail("222"+res.data.errmsg);
            }
          }
        })
        if (!this.isEmptyData(this.ticket)){
          this.init()
        }
      }
    },
    init(){
      //https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=ACCESS_TOKEN&type=jsapi
      const appid = 'wx5ef0e4fab3c1525d';
      const noncestr =(((1+Math.random())*0x10000)|0).toString(16).substring(1)+(((1+Math.random())*0x01000)|0).toString(16).substring(1)+(((1+Math.random())*0x0100)|0).toString(16).substring(1)+(((1+Math.random())*0x10000)|0).toString(16).substring(1);// 'Wm3WZYTPz0wzccnW12';
      let date = new Date();
      //let time = date.getTime();
      let time = parseInt(date.getTime()/1000);
      let url = location.href.split('#')[0];
      //Notify(url);
      //console.log("ticket",this.ticket)
      let sig = "jsapi_ticket="+this.ticket+"&noncestr="+noncestr+"&timestamp="+time+"&url="+url;
      //console.log("ticket",sig)
      sig = sha1(sig);
      //console.log("sha1",sig);
      wx.config({
        debug: false,
        appId: appid,//wx5ef0e4fab3c1525d
        timestamp: time,
        nonceStr: noncestr,
        signature: sig,
        jsApiList: [],
        openTagList: ['wx-open-launch-app'],
      });
      wx.ready(function (){
        wx.checkJsApi({
          jsApiList: ["wx-open-launch-app"],
          success: function (e1) {
            //console.log('ok',e1)
          },
          fail: (e2) => {
            //console.log("fail",e2);
          }
        })

      });
      wx.error(function (err){
        console.log("err",err)
      })
    },
    handleErrorFn(e){
      /*var btn = document.getElementById('openapp');
      btn.addEventListener('WeixinOpenTagsError', function (e) {
        Toast.fail(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
      });*/
      //this.$data.wechatOpenAppData ="【这里是error 函数】"+ JSON.stringify(e)
      window.location="https://a.app.qq.com/o/simple.jsp?pkgname=com.fourbull.pm";
      console.log("err",e);
    },
    handleLaunchFn(e){
      //this.$data.wechatOpenAppData ="【这里是launch 函数】"+ JSON.stringify(e)
      console.log("err",e)
    },
  },
}
</script>

<style scoped>

</style>